import axios, { Axios, AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  ErrorMessages,
  IEntitiesListResponse,
  IEntity,
  IEntityResponse,
  IListPayload,
  ILoginCheckResponse,
  ISearchQueryPayload,
  LocaleCodes,
  IUserList,
  ILicenseServerRepo,
  IRequestLimiter
} from '../../types'

const apiURLs = new Map([
  ['User:Login', '/license/v1/auth/login'],
  ['User:Logout', '/license/v1/auth/logout'],
  ['User:Registration', '/license/v1/auth/registration'],
  ['User:Account', '/license/v1/user'],
  ['EntityTypeMetadata:License', '/license/v1/entityTypeMetadata/License'],
  ['EntityTypeMetadata:Instance', '/license/v1/entityTypeMetadata/Instance'],
  ['EntityTypeMetadata:Customer', '/license/v1/entityTypeMetadata/Customer'],
  ['EntityTypeMetadata:Product', '/license/v1/entityTypeMetadata/Product'],
  ['EntityTypeMetadata:Feature', '/license/v1/entityTypeMetadata/Feature'],
  ['License', '/license/v1/license'],
  ['Instance', '/license/v1/instance'],
  ['Customer', '/license/v1/customer'],
  ['Product', '/license/v1/product'],
  ['Feature', '/license/v1/featureMetadata'],
  ['Activation', '/license/v1/activation'],
  ['Realm', '/gateway/realm/v1/license'],
  ['Client', '/gateway/realm/v1']
])

export class LicenseServerRepository implements ILicenseServerRepo {
  readonly #api: Axios
  readonly #errorMessages: ErrorMessages = {
    ru: {
      invalidRequest: 'Неверный запрос',
      noRights: 'Отсутствуют права для совершения данного действия',
      notFound: 'Запрашиваемые данные не найдены',
      notAllowed: 'Метод не поддерживается сервером',
      timeoutExceeded: 'Превышено время ожидания. Попробуйте повторить запрос позднее',
      invalidDataFormat: 'Недопустимый формат данных',
      unrecognizedError: 'Неопознанная ошибка',
      fileDownloadError: 'Невозможно скачать файл'
    },
    en: {
      invalidRequest: 'Bad request',
      noRights: 'No rights to perform this action',
      notFound: 'Requested data not found',
      notAllowed: 'Method Not Allowed',
      timeoutExceeded: 'Timeout exceeded. Please try again later',
      invalidDataFormat: 'Invalid data format',
      unrecognizedError: 'Unrecognized error',
      fileDownloadError: 'Unable to download the file'
    }
  }
  #urlBuilder(payload: IEntity) {
    return `
      ${apiURLs.get(payload.type)}/
      ${payload.id}
      ${payload.subEntity ? `/${payload.subEntity}` : ''}
    `.replace(/\s{2,}/g, '')
  }
  #camelCaseBuilder(path: string) {
    return path.replace(/\.[a-zA-Zа-яА-Я]/g, (match) => match.slice(1).toUpperCase())
  }
  #queryParamsBuilder(payload?: ISearchQueryPayload[], limiter?: IRequestLimiter) {
    const queryParamObject = payload?.length
      ? payload.reduce<{ [key: string]: string | number | null | undefined }>(
          (acc, { key, value }) => {
            acc[this.#camelCaseBuilder(key)] = value
            return acc
          },
          {}
        )
      : {}
    if (limiter?.orderBy) {
      queryParamObject.orderBy = this.#camelCaseBuilder(limiter.orderBy)
    }
    if (limiter?.offset) {
      queryParamObject.offset = limiter.offset
    }
    if (limiter?.limit) {
      queryParamObject.limit = limiter.limit
    }
    return queryParamObject
  }

  constructor(locale: LocaleCodes = 'ru') {
    this.#api = axios.create()
    this.#api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              if (error.config?.responseType === 'arraybuffer') {
                throw new Error(this.#errorMessages[locale].fileDownloadError)
              }
              throw new Error(
                error.response?.data?.Title || this.#errorMessages[locale].invalidRequest
              )
            case 401:
              if (!document.location.pathname.includes('login')) {
                return (document.location.href = '/login')
              }
              return false
            case 403:
              throw new Error(error.response?.data?.Title || this.#errorMessages[locale].noRights)
            case 404:
              throw new Error(error.response?.data?.Title || this.#errorMessages[locale].notFound)
            case 405:
              throw new Error(error.response?.data?.Title || this.#errorMessages[locale].notAllowed)
            case 408:
              throw new Error(
                error.response?.data?.Title || this.#errorMessages[locale].timeoutExceeded
              )
            case 415:
              throw new Error(
                error.response?.data?.Title || this.#errorMessages[locale].invalidDataFormat
              )
            case 500:
            case 502:
            case 503:
            case 504:
              throw new Error('server_error')
            default:
              throw new Error(this.#errorMessages[locale].unrecognizedError)
          }
        } else {
          throw new Error(this.#errorMessages[locale].unrecognizedError)
        }
      }
    )
  }

  checkLoggedIn(): Promise<ILoginCheckResponse> {
    return this.#api
      .get(String(apiURLs.get('User:Account')))
      .then((response: AxiosResponse<IUserList> | false) => {
        if (response && response.status === 200) {
          return {
            isUserLogged: true,
            userInfo: response.data
          }
        }
        return { isUserLogged: false }
      })
      .catch((error) => {
        if (error instanceof Error) {
          throw new Error(error.message)
        }
        throw new Error('Unexpected error')
      })
  }
  login(username: string, password: string): Promise<boolean> {
    return this.#api
      .post(String(apiURLs.get('User:Login')), { username, password })
      .then((response) => {
        if (response) return true
        throw new Error('wrong_data')
      })
      .catch((error) => {
        if (error instanceof Error) {
          throw new Error(error.message)
        }
        throw new Error('Unexpected error')
      })
  }
  logout(): Promise<boolean> {
    return this.#api
      .post(String(apiURLs.get('User:Logout')))
      .then((response) => response.status === 204)
  }
  async getEntity<T>(payload: IEntity & { subEntity?: string }): Promise<IEntityResponse<T>> {
    if (payload.id && apiURLs.has(payload.id)) {
      const response = await this.#api.get(String(apiURLs.get(payload.id)))
      return response.data
    } else if (payload.type && payload.id && apiURLs.has(payload.type)) {
      const response = await this.#api.get(this.#urlBuilder(payload))
      if (!response) {
        throw new Error(
          `Failed! ID: ${payload.id}, type: ${payload.type}, sub-entity: ${payload.subEntity}`
        )
      }
      return { entity: response.data }
    }

    throw new Error('Invalid entity id or type')
  }
  async getEntitiesList<T>(payload: IListPayload): Promise<IEntitiesListResponse<T>> {
    if (typeof payload.filter?.q === 'string') {
      throw new Error('q parameter cannot be a string')
    }
    const queryKey = payload.limiter.id || payload.limiter.type
    const params = this.#queryParamsBuilder(payload.filter?.q, payload.limiter)

    if (queryKey && apiURLs.has(queryKey)) {
      try {
        const queryURL = String(apiURLs.get(queryKey))
        const response = await this.#api.get(queryURL, { params })

        if (payload.limiter.type === 'EntityTypeMetadata') {
          return {
            results: [response.data.entity]
          }
        }

        return {
          results: response.data.items || response.data,
          count: response.data.totalItems
        }
      } catch (error) {
        if (error instanceof Error) {
          throw new Error(error.message)
        }

        throw new Error('Unexpected error')
      }
    }

    throw new Error('Invalid list type')
  }
  createEntity(payload: IEntity): Promise<IEntity> {
    if (apiURLs.has(payload.type)) {
      return this.#api
        .post<IEntity>(String(apiURLs.get(payload.type)), payload)
        .then((response) => response.data)
    }

    throw new Error('Invalid entity type')
  }
  updateEntity(payload: IEntity & { subEntity?: string }): Promise<IEntityResponse> {
    if (apiURLs.has(payload.type)) {
      return this.#api
        .patch<IEntity>(this.#urlBuilder(payload), payload)
        .then((response) => ({ entity: response.data }))
    }

    throw new Error('Invalid entity type')
  }
  async deleteEntity(payload: IEntity): Promise<boolean> {
    if (apiURLs.has(payload.type)) {
      return (await this.#api.delete(this.#urlBuilder(payload))) && true
    }

    throw new Error('Invalid entity type')
  }
  getPermissions(payload: string[]): Promise<string[]> {
    return Promise.resolve(payload)
  }
  async fileDownload(payload: IEntity & { subEntity?: string; config?: AxiosRequestConfig }) {
    if (!apiURLs.has(payload.type)) {
      throw new Error('Invalid entity type')
    }

    try {
      const response = await this.#api.get(this.#urlBuilder(payload), payload.config)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      const disposition = response.headers['content-disposition']
      const fileNameKey = 'filename='
      const fileName = disposition.substring(
        disposition.lastIndexOf(fileNameKey) + fileNameKey.length + 1,
        disposition.lastIndexOf('"')
      )
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }
  fileUpload<T>(data: { [index: string]: any }, payload?: IEntity & { subEntity?: string }) {
    const formData = new FormData()
    Object.entries(data).forEach(([name, value]) => formData.append(name, value))
    if (payload?.type && payload?.id && apiURLs.has(payload.type)) {
      return this.#api
        .post(this.#urlBuilder(payload), formData)
        .then((response) => response.data as T)
    }

    throw new Error('Invalid entity id or type')
  }
}
