import { Connection } from '@netvision/lib-api'
import { IEntity, IPreviewPayload } from '../../types'

export class EventPreviewService {
  #cache: Map<string, Blob>
  #getFiwareHeaders: () => Promise<[string, string][]>
  #getNgsiConnection: () => Promise<Connection>

  constructor(
    getFiwareHeaders: () => Promise<[string, string][]>,
    getNgsiConnection: () => Promise<Connection>
  ) {
    this.#cache = new Map()
    this.#getFiwareHeaders = getFiwareHeaders
    this.#getNgsiConnection = getNgsiConnection
  }

  getPreviewPath(entityId: string, entityName: string) {
    if (!entityId) throw new Error(`bad eventId: '${entityId}' `)
    return `v2/blob/${entityId}?type=${entityName}`
  }

  async fetchVideoPreview(url: string, options: { signal: AbortSignal }) {
    const { signal } = options
    try {
      const headers = await this.#getFiwareHeaders()
      const response = await fetch(url, { mode: 'cors', headers, signal })

      const { status, headers: responseHeaders } = response
      if (status !== 200 || !this.isValidHeaders(responseHeaders)) {
        console.error('Preview cannot be fetched')
        return null
      }

      return response.blob()
    } catch (e) {
      console.error(e)
      return null
    }
  }

  isValidHeaders(headers: Headers) {
    return ['video/mp4', 'image/jpeg', 'image/jpeg', 'image/svg+xml'].some(
      (t) => t === headers.get('Content-Type')
    )
  }

  async loadPreview({ id, timestamp, signal, eventName, useCache = true }: IPreviewPayload) {
    const seconds = Math.floor(timestamp / 1000)
    const key = `${id}:${seconds}`

    if (useCache) {
      const cached = this.#cache.get(key)
      if (cached) {
        return Promise.resolve(cached)
      }
    }

    const { url: basePath } = (await this.#getNgsiConnection()) as unknown as Connection & {
      url: string
    }
    const url = `${basePath}${this.getPreviewPath(id, eventName)}`
    const result = await this.fetchVideoPreview(url, { signal })

    if (result) this.#cache.set(key, result)

    return result
  }

  async uploadFile(entity: IEntity, formData: FormData) {
    const { id, type } = entity
    const { url: basePath } = (await this.#getNgsiConnection()) as unknown as Connection & {
      url: string
    }
    const headers = await this.#getFiwareHeaders()
    const url = `${basePath}${this.getPreviewPath(id, type)}`
    return await fetch(url, {
      method: 'POST',
      body: formData,
      headers
    })
  }
}
