import { LicenseServerRepository } from './repositories/LicenseServerRepository'
import { LibApiRepository } from './repositories/LibApiRepository'
import { CubeMixin } from './mixins/CubeMixin'
import { Query, Filter } from '@cubejs-client/core'
import {
  ISearchQueryPayload,
  IEntity,
  IListPayload,
  IServerInfo,
  ILicenseServerInfoFeature
} from './types'

type TUnionRepo = (LicenseServerRepository | LibApiRepository) & Partial<CubeMixin>
type TLibProp = {
  name?: string
  mixins?: string[]
}
type TUnionRepoTypes =
  typeof LicenseServerRepository |
  typeof LibApiRepository |
  typeof CubeMixin

const LibMap = new Map<string, TUnionRepoTypes>()

LibMap.set('license-server', LicenseServerRepository)
LibMap.set('lib-api', LibApiRepository)
LibMap.set('cubejs', CubeMixin)

const getMixedInstance = (BaseClass: any, constructors: any[]) => {
  return constructors.reduce((acc, Next) => {
    Object.getOwnPropertyNames(Next.prototype).forEach((name) => {
      Object.defineProperty(
        BaseClass.prototype,
        name,
        Object.getOwnPropertyDescriptor(Next.prototype, name) || Object.create(null)
      )
    })
    return Object.assign(acc, new Next())
  }, new BaseClass())
}

export const repositoryGetter = (lib?: TLibProp) => {
  const BaseClass = LibMap.get(lib?.name || 'lib-api') || LibApiRepository
  
  const ResultInstance = lib?.mixins?.length
    ? getMixedInstance(
        BaseClass,
        lib?.mixins?.reduce((acc: any, next: string) => {
          if (LibMap.has(next)) acc.push(LibMap.get(next))
          return acc
        }, [])
      )
    : new BaseClass()
  return ResultInstance
}

export type {
  TLibProp,
  TUnionRepo,
  CubeMixin,
  ISearchQueryPayload,
  IEntity,
  IListPayload,
  IServerInfo,
  ILicenseServerInfoFeature,
  Query as CubeQuery,
  Filter as CubeFilter
}
