import cubejs, { Query } from '@cubejs-client/core'
import { ICubeApiMixin, IEntitiesListResponse } from '../../types'

export class CubeMixin implements ICubeApiMixin {
  private _cubejsApi
  private _splitResponseKeys = <T extends Record<string, unknown>>(entities: T[]): T[] => (
    entities.map((row) => (
      Object.entries(row).reduce((acc, [key, value]) => {
        // @ts-ignore
        acc[key.split('.')[1]] = value
        return acc
      }, {} as T)
    ))
  )

  constructor() {
    if (window.CUBEJS_API_URL) {
      this._cubejsApi = cubejs('SECRET', {
        apiUrl: window.CUBEJS_API_URL,
      })
    }
  }

  async cubeGetQuantity(payload: Query): Promise<number> {
    try {
      const res = await this._cubejsApi?.load(payload)
      if (!res) {
        throw new Error('Nothing was found')
      }

      return this._splitResponseKeys(res.rawData())
        .reduce((acc, { count }) => (
          acc += Number(count || 0)
        ), 0) || 0
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async cubeGetEntities<T>(payload: Query): Promise<IEntitiesListResponse<T>> {
    try {
      const res = await this._cubejsApi?.load(payload)
      if (!res) {
        throw new Error('Nothing was found')
      }
      return {
        results: this._splitResponseKeys(res.rawData())
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}
